<template>
    <v-app id="inspire">
        <admin-app-drawer v-if="isAdmin"></admin-app-drawer>
        <user-app-drawer v-else></user-app-drawer>
        <app-toolbar></app-toolbar>
        <v-main style="margin-bottom: 50px;">
            <div style="min-height:15px;"></div>
            <transition name="fade">
                <router-view/>
            </transition>
            <app-loader></app-loader>
            <v-snackbar
                v-model="$store.getters.snackbar.display"
                :bottom="$store.getters.snackbar.y === 'bottom'"
                :left="$store.getters.snackbar.x === 'left'"
                :multi-line="$store.getters.snackbar.mode === 'multi-line'"
                :right="$store.getters.snackbar.x === 'right'"
                :timeout="$store.getters.snackbar.timeout"
                :top="$store.getters.snackbar.y === 'top'"
                :vertical="$store.getters.snackbar.mode === 'vertical'"
                >
                {{ $store.getters.snackbar.text }}
                <v-btn
                    color="pink"
                    text
                    @click="snackbar"
                >
                    Zapri
                </v-btn>
            </v-snackbar>
            <gdpr-access-log></gdpr-access-log>
            <set-user-account-data v-if="!isAdmin && updateAccountData && loggedIn "></set-user-account-data>
            <network-status></network-status>
        </v-main>
        <app-fab></app-fab>
    </v-app>
</template>

<script>
import AppFab from '@/components/layout/AppFab'
import AppToolbar from '@/components/layout/AppToolbar'
import AdminAppDrawer from '@/components/layout/AdminAppDrawer'
import UserAppDrawer from '@/components/layout/UserAppDrawer'
import { Role } from '@/helpers/role'
import { getUserRole } from '@/helpers/utilities'
import { EventBus } from '@/main.js'
const NetworkStatus = () =>import('@/components/NetworkStatus')

export default {
    components: {
        AppFab,
        AppToolbar,
        AdminAppDrawer,
        UserAppDrawer,
        NetworkStatus
    },

    props: {
      source: String
    },

    data: () => ({
        //isAdmin: false,
        resourcesInterval: null,
        adminResourcesInterval: null,
    }),

    watch: {

    },

    computed: {
        isAdmin() {
            if(getUserRole() === Role.Admin) {
                return true
            } 
            else {
                return false
            }
        },

        updateAccountData() {
            if(this.$store.getters.user.reset != 1 || ((this.$store.getters.user.email == '' || this.$store.getters.user.email == null))) {
                return true
            }

            return false
        },

        loggedIn() {
            if(this.$store.getters.user.access_token == '' || this.$store.getters.user.access_token == null) {
                return false
            }

            return true
        }


    },

    methods: {
        snackbar() {
            this.$store.state.snackbar.display = false
        },

        getResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('RESOURCES')
            .then(response => {
                //window.console.log("### RootView@getResources ###");
                //window.console.log(response.data)
                
                if(response.data.hasOwnProperty('user_types')) {
                    vm.$store.commit('SET_USER_TYPES', response.data.user_types)
                }

                if(response.data.hasOwnProperty('permit_types')) {
                    vm.$store.commit('SET_PERMIT_TYPES', response.data.permit_types)
                }

                if(response.data.hasOwnProperty('residence_types')) {
                    vm.$store.commit('SET_RESIDENCE_TYPES', response.data.residence_types)
                }

                if(response.data.hasOwnProperty('entities')) {
                    vm.$store.commit('SET_ENTITIES', response.data.entities)
                }

                if(response.data.hasOwnProperty('vehicle_ownership_types')) {
                    vm.$store.commit('SET_VEHICLE_OWNERSHIP_TYPES', response.data.vehicle_ownership_types)
                }

                if(response.data.hasOwnProperty('application_statuses')) {
                    vm.$store.commit('SET_APPLICATION_STATUSES', response.data.application_statuses)
                }

                if(response.data.hasOwnProperty('permit_statuses')) {
                    vm.$store.commit('SET_PERMIT_STATUSES', response.data.permit_statuses)
                }

            })
            .catch(error => {
                window.console.error(error)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 1000)
            })
        },

        getAdminResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                //window.console.log(response.data)
                if(response.data.hasOwnProperty('payment_statuses')) {
                    vm.$store.commit('SET_PAYMENT_STATUSES', response.data.payment_statuses)
                }

                if(response.data.hasOwnProperty('parking_lots')) {
                    vm.$store.commit('SET_PARKING_LOTS', response.data.parking_lots)
                }

                if(response.data.hasOwnProperty('pillars')) {
                    vm.$store.commit('SET_PILLARS', response.data.pillars)
                }

            })
            .catch(error => {
                window.console.error(error)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 1000)
            })
        }
    },

    created() {
        //window.console.log(this.$store.getters.user);
         /*if(getUserRole() === Role.Admin && this.$store.getters.user.type === Role.Admin) {
            this.isAdmin = true
            
        } 
        else {
            this.isAdmin = false
        }*/

        this.getResources();

    },

    mounted() {
        var vm = this;
        //this.getResources();
        this.resourcesInterval = setInterval(() =>{
            vm.getResources();
        }, process.env.VUE_APP_DATA_VUE_APP_DATA_RESOURCES_REFRESH_INTERVAL || 180000);

        if(this.isAdmin == true) {
            //window.console.log(this.isAdmin)
            vm.getAdminResources()
            this.adminResourcesInterval = setInterval(() => {
                vm.getAdminResources()
            }, process.env.VUE_APP_DATA_VUE_APP_DATA_RESOURCES_REFRESH_INTERVAL || 180000);
            
            

            let localStorageUser = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
            let gdpr_flag = window.localStorage.getItem('gdpr_flag') || null

            if(localStorageUser && localStorageUser.id != null) {
                if(gdpr_flag == null || gdpr_flag == undefined) {
                    this.$store.commit('SET_GDPR_ACCESS_LOG', true)
                } else {
                    let flag_value = (gdpr_flag == 1) ? true : false;
                    this.$store.commit('SET_GDPR_ACCESS_LOG', flag_value)
                }
            }
        }

        EventBus.$on('ROOT_BUS', function(data) {
            if(data.hasOwnProperty('clearResourcesInterval')) {
                clearInterval(vm.resourcesInterval)
            }

            if(data.hasOwnProperty('clearAdminResourcesInterval')) {
                clearInterval(vm.adminResourcesInterval)
            }
        });
        
    },

    beforeDestroy() {
        EventBus.$off('ROOT_BUS');
        clearInterval(this.resourcesInterval);
        clearInterval(this.adminResourcesInterval)
        this.snackbar()
    },

}


</script>

<style>
    html { overflow-y: auto !important; }
    
    .pointer {cursor: pointer;}

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    .listMarginLeft {
        margin-left:-10px !important;
    }

    .v-content__wrap {
      /*background-color: #FAFAFA !important;*/
    }

    #inspire{
        background-image: url("@../../..//assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }
</style>